<template>
  <div class="common-link">
    <router-link :to="{ name: 'CompanyContact' }">
      <div class="primary-btn consult-btn">立即咨询</div>
    </router-link>
    <router-link :to="link">
      <div class="plain-btn more-btn">了解更多</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CommonLink',
  props: {
    link: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.common-link {
  padding: 20px 0;
  display: flex;
  align-items: center;
  .plain-btn {
    margin-left: 20px;
  }
}
</style>
