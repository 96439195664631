<template>
  <div class="common-tab-view">
    <ul
      class="tab-list"
      :class="`tab-list--${column}`"
    >
      <li
        class="list-item"
        :class="{ 'list-item--active': currentTab === index }"
        v-for="(item,index) in data"
        :key="item.title"
      >
        <router-link :to="{ name: detailRouter, query: { id: item.id } }" v-if="routerType === 2">
          <h3>{{ item.title }}</h3>
        </router-link>
        <router-link :to="item.link" v-else-if="routerType === 1 && item.link">
          <h3>{{ item.title }}</h3>
        </router-link>
        <h3 v-else>{{ item.title }}</h3>
      </li>
    </ul>
    <div class="tab-content">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonTabView',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    column: {
      type: Number,
      default: 1
    },
    detailRouter: {
      type: String,
      default: ''
    },
    routerType: {
      type: Number,
      default: 2 // 1: 静态，2: 动态
    }
  },
  data() {
    return {
      currentTab: 0
    }
  },
  watch: {
    $route() {
      this.setCurrentTab()
    }
  },
  methods: {
    setCurrentTab() {
      if (this.routerType === 2) {
        this.data.some((item, index) => {
          if (this.$route.query.id * 1 === item.id) {
            this.currentTab = index
            return true
          }
        })
      } else {
        this.data.some((item, index) => {
          if (this.$route.name === item.router) {
            this.currentTab = index
            return true
          }
        })
      }
    }
  },
  created() {
    if (this.routerType === 2 && !this.$route.query.id && this.data.length) {
      this.$router.replace({ name: this.detailRouter, query: { id: this.data[0].id } })
    }
    this.setCurrentTab()
  }
}
</script>

<style lang="less" scoped>
.common-tab-view {
  width: 1200px;
  margin: 0 auto;
  color: #FFFFFF;
  display: flex;
  .tab-list {
    flex: none;
    width: 190px;
    padding-left: 40px;
    font-size: 18px;
    .router-link-active {
      color: inherit;
    }
    &--1 {
      .list-item {
        width: 150px;
        height: 42px;
        padding-right: 12px;
        margin-bottom: 20px;
        border-radius: 30px 0 0 30px;
        color: #FFFFFF;
        line-height: 42px;
        text-align: right;
        &--active {
          background-color: #F17D1A;
          transition: all .2s ease-in-out;
          .router-link-exact-active {
            color: #FFFFFF;
          }
        }
      }
    }
    &--2 {
      width: 240px;
      padding-left: 0;
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      .list-item {
        width: 115px;
        height: 42px;
        margin-bottom: 20px;
        border-radius: 30px;
        color: #FFFFFF;
        line-height: 42px;
        text-align: center;
        &--active {
          background-color: #F17D1A;
          transition: all .2s ease-in-out;
          .router-link-exact-active {
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .tab-content {
    width: calc(100% - 240px);
    margin-left: 30px;
    padding-left: 20px;
    border-left: 1px #FFFFFF solid;
  }
  .tab-list--2 + .tab-content {
    width: calc(100% - 290px);
    height: 496px;
  }
}
</style>
